@import "../_variables.scss";

a {
  color: $safetyOrange;

  &:hover,
  &:focus {
    color: $safetyOrange;
    opacity: 0.75;
  }
}
