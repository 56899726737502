@import "../_variables.scss";

.ant-btn.ant-btn-primary {
  background: $safetyOrange;

  & > span {
    color: $white;
  }

  &:hover,
  &:focus {
    border-color: $safetyOrange;
    color: $safetyOrange;
    opacity: 0.75;
  }
}

.ant-btn {
  border-color: $safetyOrange;
  color: $safetyOrange;
  height: 50px;
  border-radius: 4px;
  font-size: 20px;
  font-weight: 600;

  &:hover,
  &:focus {
    border-color: $safetyOrange;
    color: $safetyOrange;
    opacity: 0.75;
  }
}


