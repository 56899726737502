@import "../_variables.scss";

.ant-input-affix-wrapper {
  border-radius: 4px;
}

.ant-input-prefix {
  color: $mountainMist;
  font-size: 22px !important;
  margin: 0 13px 0 8px;
}

.ant-input {
  &:focus {
    border-color: $safetyOrange;
  }

  font-size: 16px;
  height: 40px;
}

input::placeholder {
  font-weight: 300;
  color: $magnesium;
}

.ant-form-item {
  margin-bottom: 22px;

  input[type='radio'],
  input[type='checkbox'] {
    width: 20px;
    height: 20px;

    & + .ant-checkbox-inner {
      width: 20px;
      height: 20px;
      background: none;
      border-color: $steel;
      color: $steel;

      &:after {
        border-color: $steel;
        left: 27%;
      }
    }
  }
}

.ant-checkbox + span {
  font-size: 16px;
  font-weight: 400;

  &:hover {
    color: $steel;
    opacity: 0.75;
  }
}

.ant-form {
  font-size: 16px;
}

.ant-upload-picture-card-wrapper {
  display: flex;

  .ant-upload {
    font-size: 32px;
    color: $safetyOrange;
  }

  &.borderless {
    .ant-upload-select-picture-card {
      border: none;
    }
  }

  .ant-upload-select-picture-card {
    background: none;
    border: 2px solid $safetyOrange;
    height: 84px;
    width: 84px;
    border-radius: 84px;
    margin: 0 auto;
    font-size: 32px;
    color: $safetyOrange;

    img {
      border-radius: 84px;
    }

    &:hover {
      .edit {
        display: flex;;
      }
    }

    .edit {
      //display: none;
      position: absolute;
      background: white;
      border-radius: 50%;
      color: black;
      height: 24px;
      width: 24px;
      font-size: 16px;
      align-items: center;
      justify-content: center;
      margin-left: 50px;
      margin-top: 58px;
      /* border: 1px solid gray; */
      box-shadow: 1px 0px 3px rgba(0, 0, 0, 0.6);
      -moz-box-shadow: 10px -10px 5px rgba(0, 0, 0, 0.6);
      -webkit-box-shadow: 0px -1px -10px rgba(0, 0, 0, 0.6);
      -o-box-shadow: 10px -10px 5px rgba(0, 0, 0, 0.6);
    }
  }

  .ant-upload.ant-upload-select-picture-card > .ant-upload {
    padding: 0;
  }
}

.ant-radio-checked .ant-radio-inner {
  border: 5px solid #FF9700;
}

.ant-radio-checked::after {
  border: 1px solid $safetyOrange;
}

.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
  border-color: $safetyOrange;
}

.ant-radio-inner::after {
  background-color: $safetyOrange;
}

.ant-radio-checked .ant-radio-inner::after {
  display: none;
}

.ant-form label {
  font-size: 16px;
  color: $magnesium;
}

.ant-picker {
  font-size: 16px;
  height: 50px;
  border-radius: 4px;
  width: 100%;
}

.ant-picker.ant-picker-focused {
  border-color: $safetyOrange;
}

.ant-picker-input > input:placeholder-shown {
  font-size: 16px;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 50px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  font-size: 16px;
}

.dashboard-level-selector {
  .ant-slider {
    height: 6px;
    margin: 3px 4px 6px;
    top: 0;
    line-height: normal;
  }

  .ant-slider-rail {
    height: 6px;
    background: $gallery;
    border-radius: 3px;
  }

  .ant-slider-track {
    height: 6px;
    background: linear-gradient(90deg, #FFC700 0%, #FF5C00 100%);
  }

  .ant-slider-dot:first-child {
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    border-color: #FFC700;
    height: 6px;
    background-color: #FFC700;
    top: 0;
  }

  .ant-slider-dot:last-child {
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    border-color: $gallery;
    height: 6px;
    background-color: $gallery;
    top: 0;
  }

  .ant-slider-dot:not(:first-child):not(:last-child) {
    border-radius: 0;
    border-color: $white;
  }

  .ant-slider-mark-text {
    display: none;
  }

  .ant-slider-handle {
    border: 3px solid $white;
    height: 16px;
    width: 16px;
    background-color: #FF5C00;

    box-shadow: 1px 0px 3px rgba(0, 0, 0, 0.6);
    -moz-box-shadow: 10px -10px 5px rgba(0, 0, 0, 0.6);
    -webkit-box-shadow: 0px -1px -10px rgba(0, 0, 0, 0.6);
    -o-box-shadow: 10px -10px 5px rgba(0, 0, 0, 0.6);
  }
}

.set-level-selector {
  .ant-slider-track,
  .ant-slider-step {
    display: none;
  }

  .ant-slider-rail {
    height: 8px;
    background: linear-gradient(270deg, #FF9700 1.94%, #F6DE02 97.01%);
    border-radius: 4px;
    transform: translateX(-20px);
    width: calc(100% + 40px);
  }

  .ant-slider-handle:focus,
  .ant-slider-handle:hover,
  .ant-slider-handle {
    height: 40px;
    width: 40px;
    top: -12px;
    border: 1px solid $safetyOrange;
  }

  .ant-slider-mark-text {
    font-weight: 600;
    font-size: 20px;
    text-transform: capitalize;
  }

  .ant-slider-with-marks {
    padding: 0;
    height: 8px;
    margin: 36px 0 0;
  }

  .ant-slider-mark {
    top: 40px;
  }
}

.autocomplete-dropdown-container {
  background-color: #fff;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  padding: 10px;
  position: absolute;
  width: 100%;
  z-index: 100;
}

.autocomplete-dropdown-container:empty {
  display: none;
}

.ant-form-item-control-input-content > input.location {
  height: 50px;
  border-radius: 4px;
  padding-left: 54px;

  //& + span {
  //  display: block;
  //  position: absolute;
  //  left: 18px;
  //  font-size: 22px;
  //  top: 14px;
  //  color: $mountainMist;
  //}
}

.ant-form-item {
  .ant-select-single .ant-select-selector .ant-select-selection-search {
    margin-left: 44px;
  }

  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    margin-left: 44px;
  }
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
  height: 50px;
  border-radius: 4px;
  display: flex;
  align-items: center;
}

.university-select {
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    padding-left: 0;
    font-size: 30px;
    font-weight: 600;
  }

  .ant-select-single .ant-select-selector .ant-select-selection-item,
  .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    padding-left: 0;
    font-weight: 400;
  }

  .ant-select-arrow {
    color: $black;
    font-size: 16px;
  }
}

.withPrefix {
  .ant-select + span.anticon {
    position: absolute;
    font-size: 20px;
    color: #949494;
    left: 19px;
    top: 14px;
  }

  .ant-select-selection-item {
    margin-left: 44px;
  }
}


.ant-picker-input {
  flex-direction: row-reverse;

  .ant-picker-suffix {
    font-size: 20px;
    color: $mountainMist;
    margin: 0 18px 0 5px;
  }

  & > input {
    font-size: 16px;
  }
}

.ant-input-group {
  display: flex;
  flex-direction: row-reverse;

  input {
    padding-left: 40px;
    font-size: 18px;
  }
}

.ant-layout-header {

  .ant-input-affix-wrapper {
    padding: 0;
    font-size: 18px;
  }

}

.ant-dropdown {
  .ant-dropdown-menu {
    padding: 10px 15px;
    background-color: $white ;

    .ant-dropdown-menu-item {
      padding: 0px;
      margin: 0;

      &:not(:first-child):not(:last-child) {
        border-top: 1px solid $gallery;
      }

      button {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: inherit;
        border: 1px solid $safetyOrange;
        color: $safetyOrange;
        font-size: 16px;
        border-radius: 4px;
        height: 40px;
        padding: 0 30px;
        font-weight: 300;
        margin: 10px 0;

        &:hover {
          opacity: 0.75;
        }
      }
    }

    .ant-dropdown-menu-item:hover, .ant-dropdown-menu-submenu-title:hover {
      background-color: inherit;
    }

    .ant-dropdown-menu-item > a, .ant-dropdown-menu-submenu-title > a {
      margin: 0;
      padding: 10px 0px;
      color: $steel;
      font-size: 14px;
      font-weight: 300;
      min-width: 144px;
    }
  }
}