@import "../_variables.scss";

body {
  font-family: 'SegoeUI', sans-serif;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  font-style: normal;
  height: 100vh;
  min-height: 100vh;
}

.hide {
  display: none;
}

.bold {
  font-weight: 500;
}

h1 {
  font-weight: 600;
  font-style: normal;
  font-size: 30px;
  line-height: 42px;
  letter-spacing: 0.5px;
}

h2 {
  font-weight: 300;
  font-style: normal;
  font-size: 24px;
  line-height: 33.6px;
  letter-spacing: 0.5px;
}

h3 {
  font-weight: 500;
  font-style: normal;
  font-size: 24px;
  line-height: 32px;

  &.bold {
    font-weight: 700;
  }
}

h4 {
  font-weight: 500;
  font-style: normal;
  font-size: 20px;
  line-height: 28px;

  &.bold {
    font-weight: 700;
  }
}

h5 {
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  line-height: 24px;

  &.bold {
    font-weight: 700;
  }
}

.subHeading {
  font-weight: 400;
  font-style: normal;
  font-size: 18px;
  line-height: 24px;
}

.caption {
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.35px;
}

.primary {
  color: $safetyOrange;
}
