.ant-modal-footer {
  padding: 0 25px 16px;
  border-top: none;
  display: flex;
  justify-content: space-between;

  button {
    min-width: 45%;
  }
}

.ant-modal-body {
  padding: 24px 24px 2px;
}

.only-cancel .ant-modal-footer {
  justify-content: space-around;
}