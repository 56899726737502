@import "../_variables.scss";

.site-layout {
  padding: 0 100px 0 30px;
  background-color: $hintOfRed;
}

.ant-layout-header {
  display: flex;
  align-items: center;
  background-color: $hintOfRed;
  justify-content: space-between;
  font-size: 16px;
  padding: 64px 0;
}

.site-layout-background {
  min-height: 360px;

  .ant-upload-picture-card-wrapper .ant-upload-select-picture-card {
    height: 104px;
    width: 104px;
    border-radius: 104px;
    font-size: 32px;

    .edit {
      left: 25px;
      top: 25px;
    }
  }
}

.ant-layout-sider {
  background-color: $white;
  padding: 40px 10px 0 100px;
}

.ant-menu-root{
  margin-top: 54px;

  .ant-menu-item {
    height: 54px;
    font-size: 18px;
    color: $steel;
    display: flex;
    align-items: center;
    font-weight: 300;

    .anticon {
      font-size: 18px;
      margin-right: 24px;
    }
  }
}

.ant-menu-inline .ant-menu-item::after {
  border: none;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  color: $safetyOrange;
  background-color: $oldLace;
}

.ant-menu-inline {
  border-right: none;
}

.ant-menu-inline .ant-menu-item:not(:last-child) {
  margin-bottom: 10px;
}
