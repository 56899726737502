@import "../_variables.scss";

.ant-steps-item-process {
  //.ant-steps-item-icon {
  //  & > .ant-steps-icon {
  //    .ant-steps-icon-dot {
  //      background: $safetyOrange
  //    }
  //  }
  //}
  .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
    background: $safetyOrange;
  }
}

.ant-steps-item-wait {
  .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
    background: $zircon;
  }
}

.ant-steps-item-finish {
  .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
    background: $safetyOrange;
    display: none;
  }

  & > .ant-steps-item-container {
    & > .ant-steps-item-tail::after {
      background-color: $safetyOrange;
    }

    & > .ant-steps-item-content > .ant-steps-item-title {
      color: $safetyOrange;
    }
  }
}

.ant-steps-item-title {
  font-size: 18px;
  font-weight: 400;
}

[data-theme='compact'] .site-navigation-steps,
.site-navigation-steps {
  margin-bottom: 60px;
}


.ant-steps-label-vertical .ant-steps-item-content {
  margin-top: -46px;
}

.ant-steps {
  .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .ant-steps-item-title,
  .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .ant-steps-item-subtitle,
  .ant-steps-item:not(.ant-steps-item-active) > .ant-steps-item-container[role='button']:hover .ant-steps-item-description {
    color: inherit;
  }
}

.ant-steps-dot.ant-steps-horizontal .ant-steps-item-tail::after, .ant-steps-dot.ant-steps-small .ant-steps-item-tail::after {
  height: 2px;
  width: 100%;
  margin-left: 0;
}

.ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
  display: none;
}

